<template>
  <div class="text-left ResetPasswordNew"  style="overflow-x:hidden;">
    <div class="row h-100">
      <div class="col-12 col-sm-12 col-md-12 col-lg-7 MainSection">
        <div>
          <!-- Logo -->
          <div class="px-5">
            <img
              src="/assets/img/new-website/LogoQuizell.svg"
              style="height: 40px; cursor: pointer"
              alt="Quizell"
              class="img-fluid"
              fluid
              @click="$router.push('/')"
            />
          </div>

          <div class="TitleDiv">
            <p class="title">Forgot your password?</p>
            <p class="SubTitle text-center">
              Don't worry. Enter your email. We'll send you a password reset
              link to reset your password.
            </p>
          </div>

          <!-- Inputs -->
          <div class="mt-5 px-5">
            <div class="new-form-element px-5">
              <p class="m-0 pl-1">Email</p>
              <div class="NewFormInput d-flex align-items-center w-100 py-2">
                <input
                  type="email"
                  class="mx-2 w-100 flex-grow-1"
                  placeholder="Enter your  email"
                  v-model="userDetail.Email"
                />
              </div>
            </div>

            <div class="mt-4 px-5">
              <button
                @click="SubmitResetPassword"
                :disabled="!isEmailValid"
                class="btn w-100 NewFormInput-Btn px-3 py-2"
              >
                <span v-if="!SubmitPasswordSpinner">Reset Password</span>
                <div
                  v-else
                  class="spinner-border spinner-border-sm"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="d-none d-lg-block col-lg-5 h-100 objectSection"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from 'axios';
export default {
  data() {
    return {
      userDetail: {
        Email: "",
      },
      SubmitPasswordSpinner: false,
    };
  },
  methods: {
    async SubmitResetPassword() {
      this.SubmitPasswordSpinner = true;
      try {
        let data = {
          email: this.userDetail.Email,
          _token: this.GetTokenFromMetaTag,
        };
        const response = await axios.post(`/password/email`, data);
        if (response.status == 200 && response.data.status == "fail") {
          this.$toasted.show("No Account found on this email ", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        } else if (
          response.status == 200 &&
          response.data.status == "success"
        ) {
          this.$toasted.show("Reset Password link generated", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
          this.userDetail.Email = "";
        }
      } catch (error) {
        if (error) {
          this.$toasted.show("Error occured ", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
        throw error;
      } finally {
        this.SubmitPasswordSpinner = false;
      }
    },
  },
  computed: {
    isEmailValid() {
      return this.userDetail.Email !== "";
    },
    ...mapGetters(["GetTokenFromMetaTag"]),
  },
};
</script>

<style scoped>
.ResetPasswordNew {
  height: 100vh;
}
.ResetPasswordNew .MainSection {
  padding: 2% 5%;
  background: #ffffff;
}
.MainSection .TitleDiv {
  padding: 48px;
}
.MainSection .title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 60px;
  text-align: center;
  color: #161e34;
}
.objectSection {
  background-image: url("/assets/img/new-website/loginObject.png");
  background-color: #ffffff;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100vh;
}

.SignUpCheckbox p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  margin-top: 2px !important;
  color: #161e34;
}
.SignUpCheckbox a {
  text-decoration: none;
  color: #4d1b7e;
}

.NewFormInput-Btn {
  background: #4d1b7e;
  border-radius: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
}
.NewFormInput-Btn:hover {
  background: #ffc000;
  color: #ffffff;
}
.NewFormInput-Btn:disabled:hover {
  background: #4d1b7e;
  color: #ffffff;
}

.MainSection .register {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #161e34;
}
.MainSection .register button {
  color: #4d1b7e;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

@media (max-width: 560px) {
  .MainSection .title {
    font-weight: 500;
    font-size: 26px;
    line-height: 60px;
  }

  .MainSection .TitleDiv {
    padding: 28px;
  }
}
    .NewFormInput {
        border: 1px solid #4D1B7E;
    border-radius: 10px;
        box-sizing: border-box;
        background:#ffffff;
    }
    
    .NewFormInput input {
        background: transparent;
        border: none;
        outline: none;
    }
    .NewFormInput input:focus {
        border: none;
        outline: none;
    }
    .NewFormInput input::placeholder {
       font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    
    text-align: start;
    
    color: #D1D1D1;
    }
    
</style>
